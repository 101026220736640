import React, { Component } from 'react';

import Image from '../../slices/Image';
import ImageModel from '../../../models/slices/Image';
import Link from '../../Link';
import VideoModel from '../../../models/slices/Video';
import { getCmsValue } from '../../../utils/utils';

class ArticleItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { article, site } = this.props;

    const media = article.featuredMedia.media;

    let previewComponent;
    if (media instanceof ImageModel) {
      previewComponent = <Image image={media} />;
    } else if (media instanceof VideoModel) {
      previewComponent = <img src={media.video.previewUrl} />;
    }

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
      <li className="article-item">
        <div className="article-item__featured-media">{previewComponent}</div>
        <div className="article-item__content">
          <div className="article-item__meta">
            <Link
              className="article-item__article-group"
              site={site}
              to={article.articleGroup}
            >
              {getCmsValue(article.articleGroup.title, site)}
            </Link>
            <span className="article-item__article-date">
              {article.published.toLocaleDateString('en-US', dateOptions)}
            </span>
          </div>
          <Link className="article-item__title" site={site} to={article}>
            {getCmsValue(article.title, site)}
          </Link>
          <Link className="article-item__read-more" site={site} to={article}>
            <span>Read more</span>
          </Link>
        </div>
      </li>
    );
  }
}

export default ArticleItem;
