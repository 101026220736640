import { graphql } from 'gatsby';
import React, { Component } from 'react';

import ArticleFragment from './fragments/Article';
import ArticleGroupModel from '../models/ArticleGroup';
import ArticleModel from '../models/Article';
import ArticlesComponent from '../components/articles/articles/Articles';
import breadcrumbs from './breadcrumbs';
import jsonLd from './schemas';
import { mapEdgesToNodes } from '../utils/utils';

export const query = graphql`
  query ($id: String!) {
    sanityArticleGroup(id: { eq: $id }) {
      ingress {
        languagePrimary
        languageSecondary
      }
      title {
        languagePrimary
        languageSecondary
      }
      uid {
        current
      }
    }
    allSanityArticle(filter: { articleGroup: { id: { glob: $id } } }) {
      edges {
        node {
          ...Article
        }
      }
    }
  }
`;

class Articles extends Component {
  render() {
    const { brand, site, path } = this.props;

    const { siteUID } = this.props.pageContext;
    const { sanityArticleGroup, allSanityArticle } = this.props.data;

    const articleGroup = new ArticleGroupModel(sanityArticleGroup, siteUID);
    const articles = mapEdgesToNodes(allSanityArticle)
      .map((article) => new ArticleModel(article, siteUID, []))
      .sort((a, b) => (a.published > b.published ? -1 : 1));

    return (
      <ArticlesComponent
        articleGroup={articleGroup}
        articles={articles}
        breadcrumbs={breadcrumbs('ARTICLES', articleGroup)}
        jsonLd={jsonLd(brand, site, path, articles)}
        site={site}
      />
    );
  }
}

export default Articles;
