import { getCmsValue } from '../utils/utils';

const article = (data) => {
  return [data.articleGroup, data];
};

const articles = (data) => {
  return [data];
};

const catalogs = (data) => {
  return [data];
};

const page = (data) => {
  return [data];
};

const product = (data, productMenu) => {
  const traverseMenu = (breadcrumbs, parent, menuItem, product) => {
    const subCategory = menuItem?.linkTarget?.subCategory;
    const subCategoryMatch = subCategory === product.subCategory.key;

    const subCategoryExisting = breadcrumbs.find(
      (breadcrumb) => breadcrumb.linkTarget?.subCategory === subCategory
    );

    if (subCategoryMatch && !subCategoryExisting) {
      breadcrumbs.push(menuItem);
    }

    for (const subItem of menuItem.children) {
      const category = subItem?.linkTarget?.category;
      const categoryMatch = category === product.category.key;

      const categoryExisting = breadcrumbs.find(
        (breadcrumb) => breadcrumb.linkTarget?.category === category
      );

      if (categoryMatch && !categoryExisting) {
        breadcrumbs.push(subItem);
        traverseMenu(breadcrumbs, menuItem, subItem, product);
      } else {
        traverseMenu(breadcrumbs, menuItem, subItem, product);
      }
    }

    return breadcrumbs;
  };

  const breadcrumbs = traverseMenu([], productMenu, productMenu, data);

  return breadcrumbs.map((breadcrumb) => ({
    ...breadcrumb.linkTarget,
    title: breadcrumb.title,
  }));
};

const products = (data, productMenu) => {
  // VERY MESSY, NEEDS REFACTORING
  const traverseMenu = (breadcrumbs, parent, menuItem, productGroup) => {
    const { productGroupType } = productGroup;

    const collection = menuItem?.linkTarget?.collection;
    const collectionMatch = collection === productGroup.collection;
    const collectionExisting = breadcrumbs.find(
      (breadcrumb) => breadcrumb.linkTarget?.collection === collection
    );

    const subCategory = menuItem?.linkTarget?.subCategory;
    const subCategoryMatch = subCategory === productGroup.subCategory;

    const subCategoryExisting = breadcrumbs.find(
      (breadcrumb) => breadcrumb.linkTarget?.subCategory === subCategory
    );

    if (productGroupType === 'category') {
      if (!productGroup.linkTarget?.category && !subCategory) {
        if (
          menuItem.linkTarget?.path === productGroup.path &&
          !subCategoryExisting
        ) {
          breadcrumbs.push(menuItem);
        }
      } else if (subCategoryMatch && !subCategoryExisting) {
        breadcrumbs.push(menuItem);
      }
    } else if (productGroupType === 'collection') {
      if (!collection) {
        if (menuItem.linkTarget?.path === productGroup.path) {
          breadcrumbs.push(menuItem);
        }
      } else if (collectionMatch && !collectionExisting) {
        breadcrumbs.push(menuItem);
      }
    }

    for (const subItem of menuItem.children) {
      const category = subItem?.linkTarget?.category;
      const categoryMatch = category === productGroup.category;
      const categoryExisting = breadcrumbs.find(
        (breadcrumb) => breadcrumb.linkTarget?.category === category
      );

      const collection = subItem?.linkTarget?.collection;
      const collectionMatch = collection === productGroup.collection;
      const collectionExisting = breadcrumbs.find(
        (breadcrumb) => breadcrumb.linkTarget?.collection === collection
      );

      if (productGroupType === 'category') {
        if (!category) {
          if (subItem.linkTarget?.path === productGroup.path) {
            breadcrumbs.push(subItem);
          }
        } else if (categoryMatch && !categoryExisting) {
          breadcrumbs.push(subItem);
        }
        traverseMenu(breadcrumbs, menuItem, subItem, productGroup);
      } else if (productGroupType === 'collection') {
        if (!collection) {
          if (subItem.linkTarget?.path === productGroup.path) {
            breadcrumbs.push(subItem);
          }
        } else if (collectionMatch && !collectionExisting) {
          breadcrumbs.push(subItem);
        }
        traverseMenu(breadcrumbs, menuItem, subItem, productGroup);
      }
    }

    return breadcrumbs;
  };

  const breadcrumbs = traverseMenu([], productMenu, productMenu, data);

  return breadcrumbs.map((breadcrumb) => ({
    ...breadcrumb.linkTarget,
    title: breadcrumb.title,
  }));
};

const prostaff = (data) => {};

const prostaffer = (data) => {};

const search = (data) => {
  const query = new URLSearchParams(data.search).get('q');
  return [query];
};

const stores = (data) => {
  return ['Stores'];
};

export default (type, data, menus) => {
  switch (type) {
    case 'ARTICLE':
      return article(data);
    case 'ARTICLES':
      return articles(data);
    case 'CATALOGS':
      return catalogs(data);
    case 'PAGE':
      return page(data);
    case 'PRODUCT':
      return product(data, menus.productMenu);
    case 'PRODUCTS':
      return products(data, menus.productMenu);
    case 'PROSTAFF':
      return prostaff(data);
    case 'PROSTAFFER':
      return prostaffer(data);
    case 'SEARCH':
      return search(data);
    case 'STORES':
      return stores(data);
    default:
      return [];
  }
};
