import Helmet from 'react-helmet';
import React, { Component } from 'react';

class JsonLD extends Component {
  render() {
    const { jsonLd } = this.props;

    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
    );
  }
}

export default JsonLD;
