import { getPimValue } from '../utils/utils';

export const organizationSchema = (brand) => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  '@id': `${brand.url}#organization`,
  logo: {
    '@type': 'ImageObject',
    url: brand.logo,
  },
  name: brand.name,
  url: brand.url,
});

export const breadcrumbListSchema = (brand, path) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    path
      .split('/')
      .map((part, i) => ({
        '@type': 'ListItem',
        item: `${brand.url}${path
          .split('/')
          .slice(0, i + 1)
          .join('/')}`,
        name: part.slice(0, 1).toUpperCase() + part.slice(1),
        position: i,
      }))
      .slice(1),
  ],
  name: 'BreadcrumbList',
});

export const webSiteSchema = (brand, site) => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  inLanguage: site.languagePrimary.value,
  name: 'WebSite',
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: `${brand.url}/${site.siteUID}/search?q={search_term_string}`,
    },
    'query-input': 'required name=search_term_string',
  },
  publisher: organizationSchema(brand),
  url: `${brand.url}/${site.siteUID}`,
});

export const articleSchema = (brand, site, path, article) => [
  {
    '@context': 'https://schema.org',
    '@type': 'Article',
    author: {
      ...organizationSchema(brand),
    },
    dateModified: article.updated,
    datePublished: article.published,
    headline: article.title.languagePrimary,
    image: [
      article.featuredMedia.media.src ||
        article.featuredMedia.media.video?.previewUrl ||
        brand.logo,
    ],
    inLanguage: site.languagePrimary.value,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${brand.url}#article`,
    },
    publisher: {
      '@id': organizationSchema(brand)['@id'],
    },
  },
  breadcrumbListSchema(brand, path),
];

const variantName = (product, variant, site) => {
  const name = getPimValue(product.name, site);
  const props = product.variableFields?.map((field) =>
    getPimValue(variant[field.value], site)
  );

  return `${name} - ${props.join(' / ')}`;
};

export const productSchema = (brand, site, path, product) => [
  {
    '@context': 'https://schema.org',
    '@id': `${brand.url}${path}`,
    '@type': 'ProductModel',
    aggregateRating:
      product.totalReviews > 0
        ? {
            '@type': 'AggregateRating',
            ratingCount: product.totalReviews,
            ratingValue: product.score,
          }
        : undefined,
    brand: getPimValue(product.brand),
    category: getPimValue(product.category),
    description: getPimValue(product.shortDescription, site),
    image: product.images.length > 0 ? `${product.images[0].url}/Preview` : '',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${brand.url}#product`,
    },
    name: getPimValue(product.name, site),
    offers: product.variants.map((variant) => ({
      '@type': 'Offer',
      availability:
        parseInt(variant.inventory || '0') > 0
          ? 'http://schema.org/InStock'
          : 'http://schema.org/OutOfStock',
      price: variant.price,
      priceCurrency: site.currencyCode || 'USD',
      sku: getPimValue(variant.sku, site),
    })),
    publisher: {
      '@id': organizationSchema(brand)['@id'],
    },
  },
  ...product.variants.map((variant) => ({
    '@context': 'https://schema.org',
    '@type': 'Product',
    color: getPimValue(variant.colorName, site),
    image: variant.images.length > 0 ? `${variant.images[0].url}/Preview` : '',
    isVariantOf: {
      '@id': `${brand.url}${path}`,
    },
    itemCondition: 'NewCondition',
    name: variantName(product, variant, site),
    offers: {
      '@type': 'Offer',
      availability:
        parseInt(variant.inventory || '0') > 0
          ? 'http://schema.org/InStock'
          : 'http://schema.org/OutOfStock',
      price: variant.price,
      priceCurrency: site.currencyCode || 'USD',
      sku: getPimValue(variant.sku, site),
    },
    pattern: getPimValue(variant.colorName, site),
    size: getPimValue(variant.size, site),
    sku: getPimValue(variant.sku, site),
  })),
  breadcrumbListSchema(brand, path),
];

export default (brand, site, path, doc) => {
  switch (doc.constructorName) {
    case 'Article':
      return articleSchema(brand, site, path, doc);
    case 'Articles':
      return {};
    case 'FrontPage':
      return {};
    case 'Product':
      return productSchema(brand, site, path, doc);
    case 'Products':
      return {};
    case 'Prostaff':
      return {};
    case 'Prostaffer':
      return {};
  }
};
