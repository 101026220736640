import Helmet from 'react-helmet';
import React, { Component } from 'react';

import ArticleItem from './ArticleItem';
import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import JsonLD from '../../_common/JsonLD';
import { getCmsValue } from '../../../utils/utils';

class Articles extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { articleGroup, articles, breadcrumbs, jsonLd, site } = this.props;

    return (
      <article className="page articles">
        <Helmet title={getCmsValue(articleGroup.title, site)} />
        <JsonLD jsonLd={jsonLd} />
        <Breadcrumbs breadcrumbs={breadcrumbs} site={site} />

        <header className="articles__header">
          <ul className="articles__list articles__list__featured">
            {articles.slice(0, 1).map((article) => (
              <ArticleItem key={article.path} article={article} site={site} />
            ))}
          </ul>
          <span className="articles__header-title">
            {getCmsValue(articleGroup.title)}
          </span>
          {articleGroup.slices?.map((slice) => (
            <Slice key={slice.id} slice={slice} site={site} />
          ))}
        </header>

        <ul className="articles__list articles__list__featured-sub">
          {articles.slice(1, 3).map((article) => (
            <ArticleItem key={article.path} article={article} site={site} />
          ))}
        </ul>

        <ul className="articles__list articles__list__rest">
          {articles.slice(3, -1).map((article) => (
            <ArticleItem key={article.path} article={article} site={site} />
          ))}
        </ul>
      </article>
    );
  }
}

export default Articles;
