import React, { Component } from 'react';
import { navigate } from 'gatsby';

import { AngleLeft, AngleRight, Home } from '../_common/Icons';
import Link from '../Link';
import { getCmsValue, getPimValue } from '../../utils/utils';

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { breadcrumbs, site } = this.props;

    const title = (title) => {
      if (title?.value) {
        return getPimValue(title);
      } else if (title?.languagePrimary) {
        return getCmsValue(title);
      } else {
        return '';
      }
    };

    return (
      <div className="breadcrumbs">
        <div className="breadcrumbs-wrapper">
          <button className="breadcrumbs-back" onClick={() => navigate(-1)}>
            {AngleLeft}
            Back
          </button>
          <ul className="breadcrumbs-main">
            <li className="breadcrumbs-breadcrumb">
              <Link site={site} to={site}>
                {Home}
              </Link>
            </li>
            {breadcrumbs?.map((breadcrumb, i) => (
              <li
                key={breadcrumb?.id || `breadcrumb-${i}`}
                className="breadcrumbs-breadcrumb"
              >
                <span className="breadcrumbs-breadcrumb-arrow">
                  {AngleRight}
                </span>
                <Link site={site} to={breadcrumb}>
                  {title(breadcrumb?.title || '')}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Breadcrumbs;
